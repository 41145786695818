import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import {
  getUsers,
  getUserProfile,
  getUserCredit,
  updateUserCredit,
  getUserFiles,
  getUsersByFilter,
} from './api';

const usersPerformances = (dispatch) => {
  const setUsersContext = async (data) => {
    dispatch(actions.setUsers(data));
  };
  const getUsersContext = async ({ pageNumber, users = [], userFilter = '' }) => {
    try {
      let result;
      if (userFilter.length === 0) {
        result = await getUsers({ pageNumber, users });
      } else {
        result = await getUsersByFilter({ pageNumber, users, userFilter });
      }
      if (result) {
        if (pageNumber === 1) {
          dispatch(actions.setUsers(result.users));
        } else {
          let helper = [...users, ...result.users];
          // helper.push(result.users)
          dispatch(actions.setUsers(helper));
        }
        dispatch(actions.setTotalUsers(result.total));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getUserProfileContext = async (reqData) => {
    try {
      const result = await getUserProfile(reqData);
      if (result) {
        dispatch(actions.setUserProfile(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getUserCreditContext = async (reqData) => {
    try {
      const result = await getUserCredit(reqData);
      if (result) {
        dispatch(actions.setUserCredit(result.amount));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateUserCreditContext = async (data) => {
    try {
      const result = await updateUserCredit(data);
      dispatch(actions.setUserCredit(result.credit.amount));
      dispatch(toasterActions.updateToaster({ type: 'success', message: 'User credit changed' }));
      return result;
    } catch (e) {
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Something Went wrong' }));
      console.error(e);
    }
  };

  const getUserFilesContext = async (reqData) => {
    try {
      const result = await getUserFiles(reqData);
      if (result) {
        dispatch(actions.setUserFiles(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setUserInvoicesContext = async (data) => {
    dispatch(actions.setUserInvoices(data));
  };

  const setUserProfileContext = async (data) => {
    dispatch(actions.setUserProfile(data));
  };

  return {
    setUsersContext,
    getUsersContext,
    getUserProfileContext,
    getUserCreditContext,
    updateUserCreditContext,
    getUserFilesContext,
    setUserInvoicesContext,
    setUserProfileContext,
  };
};

export default usersPerformances;
