import { getUserInvoices } from 'containers/users/container/api';
import actions from './actions';
import {
  getInvoices,
  getHubInvoices,
  getHubsOrder,
  assignToHub,
  changeOrderStatus,
  sendOrderToShipped,
  getAttachedFiles,
} from './api';

const ordersPerformances = (dispatch) => {
  const setInvoicesContext = (data) => {
    dispatch(actions.setInvoices(data));
  };
  const setOrderModalState = (data) => {
    dispatch(actions.setOrderModalState(data));
  };
  const setInvoices = async (data) => {
    dispatch(actions.setInvoices(data));
  };
  const setAttachFiles = async (data) => {
    dispatch(actions.setAttachFiles(data));
  };
  const getInvoicesContext = async (data, hubId, userId) => {
    try {
      let result;
      if (hubId) {
        result = await getHubInvoices(data, hubId);
      } else if (userId) {
        result = await getUserInvoices(userId); 
      } else if (!userId && !hubId) {
        result = await getInvoices(data);
      }
      if (result) { 
        if (data.pageNumber === 1) {
          if(userId){
            dispatch(actions.setInvoices(result.adminInvoices));
          } else {
            dispatch(actions.setInvoices(result.invoices));
          }
          
        } else {
          dispatch(actions.updateInvoices(result.invoices));
        }
        dispatch(actions.setTotalInvoices(result.total));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getHubsOrderContext = async (id) => {
    try {
      const result = await getHubsOrder(id);
      if (result) {
        dispatch(actions.setOrderHubs(result));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getAttachFileContext = async (id) => {
    try {
      const result = await getAttachedFiles(id);
      if (result) {
        dispatch(actions.setAttachFiles(result));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const assignToHubContext = async (orderId, hubId, status) => {
    try {
      const result = await assignToHub(orderId, hubId, status);
      if (result) {
        // dispatch(actions.setOrderHubs(result))
      }
    } catch (e) {
      console.error(e);
    }
  };
  const changeOrderStatusContext = async (orderId, data) => {
    try {
      const result = await changeOrderStatus(orderId, data);
      if (result) {
        // dispatch(actions.setOrderHubs(result))
      }
    } catch (e) {
      console.error(e);
    }
  };
  const sendOrderToShippedContext = async (data) => {
    try {
      const result = await sendOrderToShipped(data);
      if (result) {
        // dispatch(actions.setOrderHubs(result))
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    setInvoicesContext,
    setOrderModalState,
    setInvoices,
    getInvoicesContext,
    getHubsOrderContext,
    assignToHubContext,
    changeOrderStatusContext,
    sendOrderToShippedContext,
    setAttachFiles,
    getAttachFileContext
  };
};

export default ordersPerformances;
