import axios from 'axios';
const { REACT_APP_ORDERS_SERVICE_URL, REACT_APP_HUB_SERVICE_URL, REACT_APP_CHAT_SERVICE_URL, REACT_APP_UPLOAD_SERVICE_URL, REACT_APP_AUTHENTICATION_SERVICE_URL } =
  process.env;

export const getInvoices = async (reqData) => {
  let data = {};
  if (reqData.status) {
    data.status = reqData.status;
  }
  if (reqData.duration) {
    data.duration = reqData.duration;
  }
  if (reqData.search_string) {
    data.search_string = reqData.search_string;
  }
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/filter/?page=${reqData.pageNumber}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getHubInvoices = async (reqData, hubId) => {
  let data = {};
  data.hub_id = hubId;
  if (reqData.status) {
    data.status = reqData.status;
  }
  if (reqData.duration) {
    data.duration = reqData.duration;
  }
  if (reqData.search_string) {
    data.search_string = reqData.search_string;
  }
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/hub/invoice/filter/?page=${reqData.pageNumber}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getInvoice = async (invoiceId) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/props/${invoiceId}`)
    .then((response) => response.data.payload);
  return response;
};

export const getOrders = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/plural`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getHubsOrder = async (id) => {
  const response = await axios
    .get(`${REACT_APP_HUB_SERVICE_URL}/admin/hub/order/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const assignToHub = async (orderId, hubId, status) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/${orderId}/assign-to-hub/${hubId}/${status}`)
    .then((response) => response.data.payload);
  return response;
};

export const changeOrderStatus = async (orderId, data) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/${orderId}/status/change`, data)
    .then((response) => response.data.payload);
  return response;
};

export const sendOrderToShipped = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/post`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getComments = async (orderId) => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const getHubComments = async (orderId, hubId) => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}/${hubId}`)
    .then((response) => response.data.payload);
  return response;
};

export const getAdminComments = async (orderId) => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/admin/notes/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const deletComment = async (orderId) => {
  const response = await axios
    .delete(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const editComment = async (orderId, message) => {
  const data = { message };
  const response = await axios
    .put(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteNotification = async (orderId) => {
  const response = await axios
    .put(`${REACT_APP_CHAT_SERVICE_URL}/notification/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const deleteInvoice = async (invoiceId) => {
  const response = await axios
    .delete(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/${invoiceId}`)
    .then((response) => response.data.payload);
  return response;
};

export const readInvoice = async (invoiceId) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/read-invoice/${invoiceId}`)
    .then((response) => response.data.payload);
  return response;
};

export const updateInvoicePrice = async (invoiceId, data) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/add_price_fields/${invoiceId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const updateOrderCost = async (orderId, data) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/${orderId}/cost/change`, data)
    .then((response) => response.data.payload);
  return response;
};

export const uploadAttachedFile = async (data) => {
  const response = await axios
    .post(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getAttachedFiles = async (orderId) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${orderId}`)
    .then((response) => response.data.payload);
  return response;
}

export const downloadAttachedFiles = async (orderId, attachmentId) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${orderId}/${attachmentId}`, { responseType: 'blob' })
    .then((response) => response.data);
  return response;
}

export const deleteAttachment = async (attachmentId) => {
  const response = await axios
    .delete(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${attachmentId}`)
    .then((response) => response.data.payload);
  return response;
}

export const generatePdfInvoice=async(invoiceId)=>{
  const response=await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/${invoiceId}/generate-pdf`)
    .then((response)=>response.data.payload)
    return response;
}

export const downloadPdfInvoiceBlob=async(pdfInvoiceAddress)=>{
  const respone=await axios({
    url:process.env.REACT_APP_STATISTIC_SERVICE_URL+`/pdf-invoice/`+pdfInvoiceAddress, 
    method:'GET',
    responseType: "blob",
  }).then((response)=>response.data);
  return respone;
}

export const getFamilyLogo = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/logo`)
    .then((response) => response.data.payload);
  return response;
};